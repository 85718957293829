// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   getSavedScrollPosition
// }) => {
//   const currentPosition = getSavedScrollPosition(location)

//   window.scrollTo(...(currentPosition || [0, 0]))

//   return false
// }

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);
    
    // if (typeof window !== 'undefined') {
    //   console.log('at least this works', window.scrollY);
    // }

    // const isBrowser = () => typeof window !== "undefined";
    // isBrowser() && window.scrollTo(0, 0);

    // const isBrowser = () => typeof window !== "undefined";
    // isBrowser() && console.log('at least this works', window.scrollY);
  
    return false
  }