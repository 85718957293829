exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-faq-index-js": () => import("./../../../src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-story-index-js": () => import("./../../../src/pages/our-story/index.js" /* webpackChunkName: "component---src-pages-our-story-index-js" */),
  "component---src-pages-specs-index-js": () => import("./../../../src/pages/specs/index.js" /* webpackChunkName: "component---src-pages-specs-index-js" */),
  "component---src-pages-terms-index-js": () => import("./../../../src/pages/terms/index.js" /* webpackChunkName: "component---src-pages-terms-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

